import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { StaticQuery, graphql } from 'gatsby'

import '@csstools/normalize.css'

import './Layout.scss'


// =============================================================================
// Make certain icons available site-wide.
library.add(fab)


// =============================================================================
const Layout = ({ children, pageTitle }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => {
      const {
        title,
        description,
      } = data.site.siteMetadata

      return (
        <>
          <Helmet>
            <title>{pageTitle ? pageTitle + ' – ' : ''}{title}</title>
            <meta name="description" content={description} />
          </Helmet>

          <main className="Portfolio">{children}</main>
        </>
      )
    }}
  />
)

// -----------------------------------------------------------------------------
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
}

// -----------------------------------------------------------------------------
export default Layout
